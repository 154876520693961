import "./mapas.css";
import { Button } from "react-bootstrap";
import React from "react";

import fotoMapaHidrico from "../../../img/mapas/mapa-caxias.jpg";
import mapaIBGE from "../../../img/mapas/mapaIBGE.jpg";
import exercito from "../../../img/mapas/exercito.png";

const Mapas = () => {
  const handleRedirect = (link) => {
    window.open(link, "_blank");
  };

  return (
    <div className="container my-5 ifma">
      <div className="row">
        <div className="col-md-6 coluna">
          <div className="imagem">
            <img
              src={fotoMapaHidrico}
              alt="Fontes hídricas Caxias - Ma"
              className="img-fluid"
            />
          </div>
          <div className="conteudo">
            <h6>Fontes Hídricas Caxias - Ma</h6>

            <p>
              Projeto cadastro de fontes de abastecimento por água subterrânea:
              estado do Maranhão: relatório diagnóstico do município de Caxias
            </p>

            <Button
              onClick={() => {
                handleRedirect("https://rigeo.sgb.gov.br/handle/doc/15431");
              }}
              className="btn btn-primary"
              variant="primary"
            >
              Saiba mais
            </Button>
          </div>
        </div>

        {/* ######################### */}

        <div className="col-md-6 coluna">
          <div className="imagem">
            <img
              src={mapaIBGE}
              alt="Fontes hídricas Caxias - Ma"
              className="img-fluid"
            />
          </div>
          <div className="conteudo">
            <h6> Malha Territorial de 2010 - IBGE</h6>

            <p>
              Faz parte da coleção de mapas municipais gerados de forma
              semi-automatizada, a partir da junção das folhas do Mapeamento
              Sistemático Brasileiro produzida pelo IBGE.
            </p>

            <Button
              onClick={() => {
                handleRedirect(
                  "https://biblioteca.ibge.gov.br/biblioteca-catalogo.html?id=612168&view=detalhes"
                );
              }}
              className="btn btn-primary"
              variant="primary"
            >
              Saiba mais
            </Button>
          </div>
        </div>
      </div>

      <div className="row" />

      <div className="row">
        <div className="col-md-6 coluna">
          <div className="imagem">
            <img
              src={exercito}
              alt="Fontes hídricas Caxias - Ma"
              className="img-fluid"
            />
          </div>
          <div className="conteudo">
            <h6>Cartas Exercito - BDGEx</h6>

            <p>
              O Banco de Dados Geográficos do Exército (BDGEx) é um sistema de
              informações geográficas, disponibilizado
              pelo Exército Brasileiro.
            </p>

            <Button
              onClick={() => {
                handleRedirect(
                  "https://bdgex.eb.mil.br/bdgexapp/mobile/?l=963,953&c=-43.362200,-4.864306&z=12"
                );
              }}
              className="btn btn-primary"
              variant="primary"
            >
              Saiba mais
            </Button>
          </div>
        </div>

        {/* ######################### */}

        <div className="col-md-6 coluna">
          <div className="imagem">
            <img
              src={mapaIBGE}
              alt="Fontes hídricas Caxias - Ma"
              className="img-fluid"
            />
          </div>
          <div className="conteudo">
            <h6> Mapa Urbano</h6>

            <p>
              Faz parte da coleção de mapas municipais gerados de forma
              semi-automatizada, a partir da junção das folhas do Mapeamento
              Sistemático Brasileiro produzida pelo IBGE.
            </p>

            <Button
              onClick={() => {
                handleRedirect(
                  "https://www.openstreetmap.org/relation/332983#map=15/-4.8569/-43.3590"
                );
              }}
              className="btn btn-primary"
              variant="primary"
            >
              Saiba mais
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mapas;
