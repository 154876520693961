import React from "react";
import { Carousel } from "react-bootstrap";

import img01 from "../../../../img/ifma/ifma.jpg";
import img02 from "../../../../img/ifma/1.JPG";
import img03 from "../../../../img/ifma/2.JPG";
import img04 from "../../../../img/ifma/3.JPG";
import img05 from "../../../../img/ifma/4.JPG";
import img06 from "../../../../img/ifma/5.JPG";
import img07 from "../../../../img/ifma/6.JPG";
import img08 from "../../../../img/ifma/7.JPG";
import img09 from "../../../../img/ifma/8.JPG";
import img10 from "../../../../img/ifma/9.JPG";

import video01 from "../../../../img/ifma/video/video01.mp4";

const CarouselSlider = () => {
  return (
    <div className="h-70">
      <Carousel variant="dark h-70" controls>
       
        <Carousel.Item>
          <img className="d-block h-50 w-100" src={img02} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block h-50 w-100" src={img03} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block h-50 w-100" src={img04} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block h-50 w-100" src={img05} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block h-50 w-100" src={img06} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block h-50 w-100" src={img07} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block h-50 w-100" src={img08} alt="Third slide" />
        </Carousel.Item>

        <Carousel.Item>
          <img
            className="d-block h-50 w-100"
            src={img09}
            alt="Third slide"
          />
        </Carousel.Item>
        <Carousel.Item>
          <img
           className="d-block h-50 w-100"
            src={img10}
            alt="Third slide"
          />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default CarouselSlider;
