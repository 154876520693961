import "./page.css";
import React from "react";

import CarouselSlider from "./CarouselSlider/CarouselSlider";
import CarouselVideo from "./CarouselVideo/CarouselSlider";

const IFMA = () => {
  return (
    <div className=" my-5 page-ifma">
      <div className="col-md-4 texto-ifma">
        <div>
          <h5>IFMA Campus Caxias</h5>
          <p>
            O <strong>Instituto Federal do Maranhão (IFMA)</strong>, Campus
            Caxias, é uma instituição de ensino que oferece uma variedade de
            cursos e oportunidades educacionais. Aqui estão alguns detalhes
            sobre o campus:
          </p>
          <p>
            <b> Localização: </b>O Campus Caxias está situado na Rodovia MA-349,
            entre os quilômetros 1,524/1,525 e 3,424/3,425, no bairro Teso Duro,
            em Caxias-MA. O CEP é 65604-500. O CNPJ do campus é
            10.735.145/0012-47.
          </p>
          <p>
            <b>Cursos Oferecidos:</b>
            <ul>
              <li>Formação Inicial e Continuada</li>
              <li>Cursos Técnicos de Nível Médio</li>
              <li>Licenciaturas</li>
              <li>Bacharelados</li>
              <li>Especializações</li>
              <li>Cursos de Extensão</li>
            </ul>
          </p>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2847.1891053084905!2d-43.345284073073174!3d-4.819547469079624!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x78e9036b49a2b7d%3A0xbc0f311e32d40582!2sIFMA%20Campus%20Caxias!5e1!3m2!1spt-BR!2sbr!4v1713111546918!5m2!1spt-BR!2sbr"
          width="100%"
          height="200"
          style={{
            border: 0,
            borderRadius: "10px",
          }}
          allowfullscreen=""
          loading="lazy"
          title="IFMA Campus Caxias"
        ></iframe>

      </div>
      <div className="col-md-4 fotos-ifma">
        <CarouselSlider />
        <CarouselVideo />
      </div>
    </div>
  );
};

export default IFMA;
