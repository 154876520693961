import React from "react";
import { Col, Row } from "react-bootstrap";
import "./Status.css";

const Status = () => {
  return (
    <div>
      <div className="progress-container">
        <div className="progress-items container text-center justify-center">
          <Row lg={2} md={1} sm={1} xs={1}>
            <Col>
              <div>
                <i className="progress-icon fas fa-globe-americas"></i>
                <h3 className="icon-heading">Caxias</h3>
                <p>Maranhão - Brasil</p>
              </div>
            </Col>
            <Col>
              <div>
                <i className="progress-icon fas fa-users"></i>
                <h3 className="icon-heading">165.525</h3>
                <p>Habitantes</p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Status;
