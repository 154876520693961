import React from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import logoFooter from "../../../img/logo.png";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="bg-dark text-light">
      <Container className="py-5 footer-text">
        <Row className="gy-5">
          <Col md={3}>
            <div>
             
              <p className="py-4">
                Caxias é linda. Temos como objetivo mostrar a beleza da nossa
                cidade, e também mostrar os pontos turísticos que ela tem a
                oferecer.
              </p>
      
            </div>
          </Col>
          <Col md={3}>
            <h4 className="mb-4">Instituições relevantes</h4>
            <div className="d-flex">
              <div className="d-flex flex-row flex-md-column flex-lg-row">
                <img
                  className="footer-img"
                  style={{ height: "70px", marginRight: "15px" }}
                  src=""
                  alt=""
                />
                <div>
                  <p
                    style={{
                      color: "#999",
                      fontSize: ".8rem",
                      marginBottom: "5px",
                    }}
                  >
                    Prefeitura municipal
                  </p>
                  <h6>
                    <a href="https://www.caxias.ma.gov.br/">Prefeitura</a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="d-flex my-3">
              <div className="d-flex flex-row flex-md-column flex-lg-row">
                <img
                  style={{ height: "70px", marginRight: "15px" }}
                  src=""
                  alt=""
                />
                <div>
                  <p
                    style={{
                      color: "#999",
                      fontSize: ".8rem",
                      marginBottom: "5px",
                    }}
                  >
                    IFMA - Campus Caxias
                  </p>
                  <h6>
                    <a href="https://caxias.ifma.edu.br/">IFMA</a>
                  </h6>
                </div>
              </div>
            </div>
            <div className="d-flex">
              <div className="d-flex flex-row flex-md-column flex-lg-row">
                <img
                  style={{ height: "70px", marginRight: "15px" }}
                  src=""
                  alt=""
                />
                
              </div>
            </div>
          </Col>
          <Col md={3}>
            <div className="footer-links">
              <div>
                <h4 className="mb-4">Acessos rapidos</h4>
              </div>
              <li>
                <Link to="/">Home</Link>
              </li>
             
            </div>
          </Col>
          <Col md={3}>
            <div>
              <div>
                <h4 className="mb-4">Fale conosco</h4>
              </div>
              <div className="mb-3">
                <p>Endereço:</p>
                <p>
                  IFMA - Campus Caxias
                </p>
              </div>
              
              <div className="mb-3">
                <p>Email:</p>
                <p>
                  <a href="mailto:helder.vasconcelos@ifma.edu.br">helder.vasconcelos@ifma.edu.br</a>
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="footer-bg">
        <Container className="my-3">
          <Row>
            <Col className="d-flex justify-content-center my-3">
              <li>
                <Link to="/home">
                  <i className="fab fa-facebook-f px-3"></i>
                </Link>
              </li>
              <li>
                <Link to="/home">
                  <i className="fab fa-twitter px-3"></i>
                </Link>
              </li>
              <li>
                <Link to="/home">
                  <i className="fab fa-linkedin-in px-3"></i>
                </Link>
              </li>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="text-center">
                <p style={{ fontSize: "14px", color: "#999" }}>
                    
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default Footer;
