import React from "react";
import "./HeaderNav.css";
import { Container, Nav, Navbar } from "react-bootstrap";
import { Link, NavLink } from "react-router-dom";

const HeaderNav = () => {
  return (
    <div>
      {/* <HeaderTop></HeaderTop> */}
      <Navbar
        collapseOnSelect
        expand="lg"
        bg="light"
        variant="dark"
        sticky="top"
        className="text-center nav-background"
      >
        <Container className="py-0">
          
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto header-nav ms-lg-3">
              <Nav.Link
                className="nav-item"
                as={NavLink}
                activeStyle={{ color: "#fff" }}
                to="/home"
              >
                Pagina Inicial
              </Nav.Link>
              
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
};

export default HeaderNav;
