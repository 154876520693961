import React from "react";
// import Gallery from "../Gallery/Gallery";
import Status from "../Status/Status";
import Mapa from "./Mapas";
import Mapas from "../../Pages/pontosPages/mapas/Mapas";
import Imagens from "../pontosPages/imagens/Imagens";
import Imagen from "./Imagens";


const Home = () => {
  return (
    <div >
      <Status></Status>
      <Mapa></Mapa>
      <Mapas />
      <Imagen></Imagen>
      <Imagens />
    
    </div>
  );
};

export default Home;
