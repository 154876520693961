import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import Home from "./Pages/Home/Home";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import NotFoundPage from "./Pages/NotFound/NotFoundPage";
import Footer from "./Pages/Shared/Footer/Footer";
import HeaderNav from "./Pages/Shared/Header/HeaderNav";
import Mapas from "./Pages/pontosPages/mapas/Mapas";

import IFMA from "./Pages/pages/IFMA/page";
import Mirante from "./Pages/pages/Mirante/page";
import Veneza from "./Pages/pages/Veneza/page";
import Cultura from "./Pages/pages/CentroCultura/page";
import Paroquia from "./Pages/pages/paroquia/page";

function App() {
  return (
    <div>
      <Router>
        <HeaderNav></HeaderNav>
        <Switch>
      
          <Route exact path="/">
            <Home></Home>
          </Route>
          <Route path="/home">
            <Home></Home>
          </Route>

          <Route path="/mapas">
            <Mapas></Mapas>
          </Route>

          <Route path="/ifma">
            <IFMA />
          </Route>

          <Route path="/mirante">
            <Mirante />
          </Route>

          <Route path="/veneza">
            <Veneza />
          </Route>

          <Route path="/cultura">
            <Cultura />
          </Route>

          <Route path="/paroquia">
            <Paroquia />
          </Route>
          <Route path="*">
            <NotFoundPage></NotFoundPage>
          </Route>
        </Switch>
        <Footer></Footer>
      </Router>
    </div>
  );
}

export default App;
