import "./page.css";
import React from "react";

import CarouselSlider from "./CarouselSlider/CarouselSlider";

const Cultura = () => {
  return (
    <div className=" my-5 page-cultura">
      <div className="col-md-4 texto-cultura">
        <div>
          <h2>Centro de Cultura Acadêmico José Sarney</h2>
          <p>
            O Centro de Cultura Acadêmico José Sarney, também conhecido como
            Centro de Cultura de Caxias, é um edifício histórico localizado na
            cidade de Caxias, Maranhão. Vamos explorar a rica história e as
            características desse espaço cultural:
          </p>
          <h3>Origens Históricas:</h3>
          <ul>
            <li>
              A história do Centro remonta à Companhia da União Têxtil Caxiense,
              fundada em 1889 por três sócios visionários.
            </li>
            <li>
              Com uma área de 6000m², o edifício foi construído com pedra, cal e
              alvenaria de tijolo, além de possuir uma estrutura metálica
              inglesa e telhas francesas.
            </li>
            <li>
              Durante seu auge, a Companhia empregava 350 pessoas e produzia um
              milhão de metros de tecidos crus anualmente, grande parte
              exportada para países europeus.
            </li>
          </ul>
          <h3>Declínio e Revitalização:</h3>
          <ul>
            <li>
              Após a Segunda Guerra Mundial, a indústria têxtil entrou em
              declínio, e a Companhia foi extinta em 1954.
            </li>
            <li>
              O prédio ficou abandonado até a década de 1970, quando foi
              adquirido pela Administração Pública.
            </li>
            <li>
              Tombado pelo Departamento do Patrimônio Histórico, Artístico e
              Paisagístico do Maranhão, o edifício foi restaurado e transformado
              em um Centro Cultural.
            </li>
          </ul>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1565.2164254914203!2d-43.36535893853322!3d-4.862853995899645!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x78e9232098b2ef3%3A0x6ae2d9763f9e695a!2sCentro%20de%20Cultura%20de%20Caxias!5e1!3m2!1spt-BR!2sbr!4v1713123361389!5m2!1spt-BR!2sbr"
          width="100%"
          height="200"
          style={{
            border: 0,
            borderRadius: "10px",
          }}
          allowfullscreen=""
          loading="lazy"
          title="IFMA Campus Caxias"
        ></iframe>
      </div>
      <div className="col-md-4 fotos-cultura">
        <CarouselSlider />
      </div>
    </div>
  );
};

export default Cultura;
