import "./page.css";
import React from "react";

import CarouselSlider from "./CarouselSlider/CarouselSlider";

const Mirante = () => {
  return (
    <div className=" my-5 page-mirante">
      <div className="col-md-4 texto-mirante">
      <div>
      <h2>Mirante da Balaiada</h2>
      <p>
        O Mirante da Balaiada, localizado no Morro do Alecrim, é um dos principais pontos turísticos da cidade de Caxias, Maranhão. Inaugurado há um ano, esse mirante oferece uma vista panorâmica deslumbrante e valoriza o monumento símbolo da Guerra da Balaiada.
      </p>
      <h3>Detalhes:</h3>
      <ul>
        <li>
          <strong>Localização Estratégica:</strong> Situado ao lado do Memorial da Balaiada, o Mirante da Balaiada oferece uma vista privilegiada das ruínas do Quartel da Balaiada. Essas ruínas representam o movimento revolucionário ocorrido em Caxias no século XIX.
        </li>
        <li>
          <strong>Atrações e Facilidades:</strong> Além de ser o ponto turístico mais elevado da cidade, o Mirante concentra diversas atrações:
          <ul>
            <li>Área de Lazer: Espaço para relaxar e apreciar a paisagem.</li>
            <li>Jardim Botânico: Um ambiente verde e tranquilo.</li>
            <li>Praça de Alimentação e Quiosques: Oportunidade para saborear a culinária local.</li>
            <li>Casa de Leitura e Biblioteca: Um espaço cultural.</li>
            <li>Minizoológico: Ideal para os amantes da fauna.</li>
            <li>Córrego Artificial: Elemento paisagístico.</li>
            <li>Internet Gratuita: Para os visitantes conectados.</li>
          </ul>
        </li>
      </ul>
    </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d622.8489185406912!2d-43.35738541805688!3d-4.865427928142342!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x78e923a57423ca9%3A0x5820b78036bdab23!2sMemorial%20da%20Balaiada!5e1!3m2!1spt-BR!2sbr!4v1713119513952!5m2!1spt-BR!2sbr"
          width="100%"
          height="200"
          style={{
            border: 0,
            borderRadius: "10px",
          }}
          allowfullscreen=""
          loading="lazy"
          title="IFMA Campus Caxias"
        ></iframe>
      </div>
      <div className="col-md-4 fotos-mirante">
        <CarouselSlider />
      </div>
    </div>
  );
};

export default Mirante;
