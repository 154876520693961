import React from "react";
import { Container } from "react-bootstrap";


const ServicesForHome = () => {
 
  return (
    <div>
      <Container className="text-center my-5">
        <div className="text-center about-heading  pt-5">
          <h4>
            Pontos Históricos e Geográficos
          </h4>
          <p>Caxias está pronta para receber você</p>
        </div>
      </Container>
    </div>
  );
};

export default ServicesForHome;
