import React from "react";
import { Carousel } from "react-bootstrap";

import img01 from "../../../../img/veneza/veneza.jpg";
import img02 from "../../../../img/veneza/img01.jpg";
import img03 from "../../../../img/veneza/img02.jpg";
import img04 from "../../../../img/veneza/img03.jpg";
import img05 from "../../../../img/veneza/img04.jpg";

const CarouselSlider = () => {
  return (
    <div className="h-70">
      <Carousel variant="dark h-70" controls>
       
        <Carousel.Item>
          <img className="d-block h-50 w-100" src={img01} alt="Second slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block h-50 w-100" src={img02} alt="Third slide" />
    
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block h-50 w-100" src={img03} alt="Third slide" />
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block h-50 w-100" src={img04} alt="Third slide" />
        </Carousel.Item>
       
        <Carousel.Item>
          <img className="d-block h-50 w-100" src={img05} alt="Third slide" />
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default CarouselSlider;
