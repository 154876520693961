import React from "react";
import { Container } from "react-bootstrap";


const ServicesForHome = () => {
 
  return (
    <div>
      <Container className="text-center my-5">
        <div className="text-center about-heading  pt-5">
          <h2>
            Alguns <span>mapas</span>
          </h2>
          <p>Compilado de mapas sobre Caxias</p>
        </div>
      </Container>
    </div>
  );
};

export default ServicesForHome;
