import "./page.css";
import React from "react";

import CarouselSlider from "./CarouselSlider/CarouselSlider";

const Veneza = () => {
  return (
    <div className=" my-5 page-veneza">
      <div className="col-md-4 texto-veneza">
        <div>
          <h2>Balneário Veneza</h2>
          <p>
            O Balneário Veneza, localizado em Caxias, Maranhão, é um lugar
            encantador e de fácil acesso. Aqui estão alguns detalhes sobre esse
            destino turístico:
          </p>
          <h3>Localização e Atrações:</h3>
          <ul>
            <li>
              <strong>Localização:</strong> O Balneário Veneza está situado no
              Morro do Alecrim e oferece uma vista panorâmica deslumbrante.
            </li>
            <li>
              <strong>Prato Típico:</strong> Os visitantes podem desfrutar do
              prato típico da cidade, o famoso “Pirão de Parida”.
            </li>
            <li>
              <strong>Barzinhos:</strong> O local possui uma fonte de água
              mineral e barzinhos com nomes exóticos, como “Mistérios da Meia
              Noite”, que promovem música ao vivo com artistas locais e
              regionais.
            </li>
            <li>
              <strong>Facilidades:</strong> Há também quadras poliesportivas, um
              playground e amplo estacionamento, tudo com entrada gratuita.
            </li>
          </ul>
          <h3>Lago com Lama Negra:</h3>
          <p>
            O grande destaque do Balneário é o seu lago. Esse lago contém uma
            lama negra que, segundo estudos, possui efeitos medicinais devido ao
            alto teor de enxofre. Essa lama é utilizada no tratamento de doenças
            de pele.
          </p>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2337.338389376013!2d-43.357578531299396!3d-4.9148195948068505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x78e92ce7c79d9d7%3A0x59192b137feafe07!2sBalne%C3%A1rio%20Veneza!5e1!3m2!1spt-BR!2sbr!4v1713122006338!5m2!1spt-BR!2sbr"
          width="100%"
          height="200"
          style={{
            border: 0,
            borderRadius: "10px",
          }}
          allowfullscreen=""
          loading="lazy"
          title="IFMA Campus Caxias"
        ></iframe>
      </div>
      <div className="col-md-4 fotos-veneza">
        <CarouselSlider />
      </div>
    </div>
  );
};

export default Veneza;
