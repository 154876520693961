import "./page.css";
import React from "react";

import CarouselSlider from "./CarouselSlider/CarouselSlider";

const Paroquia = () => {
  return (
    <div className=" my-5 page-veneza">
      <div className="col-md-4 texto-veneza">
        <div>
          <h1>Paróquia São Benedito</h1>
          <p>
            A <strong>Paróquia São Benedito</strong>, localizada na cidade
            histórica de <strong>Caxias, Maranhão</strong>, é um espaço de fé,
            comunhão e espiritualidade. Aqui estão alguns destaques sobre essa
            paróquia acolhedora:
          </p>
          <h6>Localização e Contato:</h6>
          <ul>
            <li>
              Endereço: Praça Vespasiano Ramos, Rua Pe. Geroza, 829, C.P. 09,
              Caxias-MA, CEP 65.602-080.
            </li>
            <li>Secretaria: (99) 3521-5157 ou (99) 9 8851-0342.</li>
            <li>E-mail: sbeneditocx@gmail.com</li>
          </ul>
          <h6>Liderança Espiritual:</h6>
          <ul>
            <li>Pároco: Mons. José Ribamar Cavalcante Lima.</li>
            <li>Vigário: Pe. Reginaldo Bueno.</li>
          </ul>
          <h6>Atividades e Celebrações:</h6>
          <p>
            A Paróquia São Benedito realiza missas diárias e confissões,
            proporcionando momentos de oração e reflexão para a comunidade. Além
            das celebrações litúrgicas, a paróquia promove eventos, encontros e
            grupos de formação espiritual.
          </p>
          <h6>Engajamento Social:</h6>
          <p>
            A paróquia está integrada à Diocese de Caxias e participa ativamente
            de projetos sociais e ações solidárias na região.
          </p>
        </div>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15901.89251540523!2d-43.3595781!3d-4.8600916!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x78e922f403c1649%3A0x6f5fa1aad7f2dfb4!2sPar%C3%B3quia%20S%C3%A3o%20Benedito%20-%20Caxias%20MA!5e0!3m2!1spt-BR!2sbr!4v1715980745144!5m2!1spt-BR!2sbr" 
          width="100%"
          height="200"
          style={{
            border: 0,
            borderRadius: "10px",
          }}
          loading="lazy"
          title="IFMA Campus Caxias"
        ></iframe>
      </div>
      <div className="col-md-4 fotos-veneza">
        <CarouselSlider />
      </div>
    </div>
  );
};

export default Paroquia;
