import "./page.css";
import React from "react";
import { Carousel } from "react-bootstrap";

import video01 from "../../../../img/ifma/video/video01.mp4";
import video03 from "../../../../img/ifma/video/video3.mp4";
import video04 from "../../../../img/ifma/video/video4.mp4";

const CarouselVideo = () => {
  return (
    <div className="h-50 videos">
      <Carousel variant="h-100 " controls={false}    interval={null}   
>
        <Carousel.Item>
          <video
            className="w-100"
            controls
          >
            <source src={video01} type="video/mp4" />
          </video>
        </Carousel.Item>

        <Carousel.Item>
          <video className=" w-100" controls>
            <source src={video03} type="video/mp4" />
          </video>
        </Carousel.Item>
        <Carousel.Item>
          <video className=" w-100" controls>
            <source src={video04} type="video/mp4" />
          </video>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default CarouselVideo;
