import "./Imagens.css";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import React from "react";

import IFMA from "../../../../src/img/ifma/ifma.jpg";
import Mirante from "../../../../src/img/mirante.jpg";
import Centro from "../../../../src/img/centroCultura/centrocultura.jpg";
import vaneza from "../../../../src/img/veneza/veneza.jpeg";
import Paroquia from "../../../../src/img/paroquia/img01.jpg";

const Imagens = () => {
  const handleRedirect = (link) => {
    // window.open(link, "_blank");
  };

  return (
    <div className="container my-5 ifma">
      <div className="row">
        <div className="col-md-6 coluna">
          <div className="imagem">
            <img
              src={IFMA}
              alt="Fontes hídricas Caxias - Ma"
              className="img-fluid"
            />
          </div>
          <div className="conteudo">
            <h6>IFMA - Campus Caxias</h6>

            <p>
              O Instituto Federal do Maranhão (IFMA), Campus Caxias, é uma
              instituição de ensino que oferece uma variedade de cursos e
              oportunidades educacionais.
            </p>

            <Button className="btn btn-primary" variant="primary">
              <Link to="/ifma">Saiba mais</Link>
            </Button>
          </div>
        </div>

        {/* ######################### */}

        <div className="col-md-6 coluna">
          <div className="imagem">
            <img
              src={Mirante}
              alt="Fontes hídricas Caxias - Ma"
              className="img-fluid"
            />
          </div>
          <div className="conteudo">
            <h6> Mirante da Balaiada</h6>

            <p>
              O Mirante da Balaiada, localizado no Morro do Alecrim, é um dos
              principais pontos turísticos da cidade de Caxias, Maranhão.
            </p>

            <Button className="btn btn-primary" variant="primary">
              <Link to="/mirante">Saiba mais</Link>
            </Button>
          </div>
        </div>
      </div>

      <div className="row" />

      <div className="row">
        <div className="col-md-6 coluna">
          <div className="imagem">
            <img
              src={Centro}
              alt="Fontes hídricas Caxias - Ma"
              className="img-fluid"
            />
          </div>
          <div className="conteudo">
            <h6>Centro de Cultura José Sarney</h6>

            <p>
              O Centro de Cultura Acadêmico José Sarney (também conhecido como
              Centro de Cultura de Caxias) é um edifício histórico localizado na
              cidade de Caxias, Maranhão.
            </p>

            <Button className="btn btn-primary" variant="primary">
              <Link to="/cultura">Saiba mais</Link>
            </Button>
          </div>
        </div>

        {/* ######################### */}

        <div className="col-md-6 coluna">
          <div className="imagem">
            <img
              src={vaneza}
              alt="Fontes hídricas Caxias - Ma"
              className="img-fluid"
            />
          </div>
          <div className="conteudo">
            <h6> Balneário Veneza</h6>

            <p>
              Balneário Veneza, localizado em Caxias, Maranhão. O balneário é
              conhecido por seu lago, que possui uma lama negra com efeitos
              medicinais devido ao alto teor de enxofre.
            </p>

            <Button className="btn btn-primary" variant="primary">
              <Link to="/veneza">Saiba mais</Link>
            </Button>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-6 coluna">
          <div className="imagem">
            <img
              src={Paroquia}
              alt="Fontes hídricas Caxias - Ma"
              className="img-fluid"
            />
          </div>
          <div className="conteudo">
            <h6>Paróquia São Benedito</h6>

            <p>
              A Paróquia São Benedito, localizada na cidade histórica de Caxias,
              Maranhão, é um espaço de fé, comunhão e espiritualidade.
            </p>

            <Button className="btn btn-primary" variant="primary">
              <Link to="/paroquia">Saiba mais</Link>
            </Button>
          </div>
        </div>

        {/* ######################### */}
      </div>
    </div>
  );
};

export default Imagens;
